import { Card } from "../../../../@types/CorporateExpenses/Card";

interface Company {
  id: string;
  id_baas: string;
  id_baas_account: string;
  id_bag_funds_out: string | null;
  name: string;
  email: string;
  cnpj: string;
}

interface User {
  id: string | undefined;
  name: string | undefined;
  // email: string;
}

interface CostCenterData {
  id: string | undefined;
  title: string | undefined;
  // description: string;
}

export interface CorpwayDetails {
  id: string;
  baas_card_id: string;
  alias: string;
  pan: string;
  status: string;
  baas_account_id: string;
  last_recharge: string | null;
  company: Company;
  user: User;
  cost_center_data: CostCenterData;
}


export const transformCardToDetails = (card: Card): CorpwayDetails => {
  return {
    id: card.id,
    baas_card_id: card.baas_card_id,
    alias: card.alias,
    pan: card.pan,
    status: card.status,
    baas_account_id: card.baas_account_id,
    last_recharge: card.last_recharge,

    company: {
      id: card.company_data.id,
      id_baas: card.company.id_baas,
      id_baas_account: card.company.id_baas_account,
      id_bag_funds_out: card.company.id_bag_funds_out,
      name: card.company_data.name,
      email: card.company.email,
      cnpj: card.company.cnpj,
    },

    user: {
      id: card.user_data?.id,
      name: card.user_data?.name,
      // email: card.user.email,
    },

    cost_center_data: {
      id: card.cost_center_data?.id,
      title: card.cost_center_data?.title,
    },
  };
}

