import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { ResponseTypesOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const transactionTypesOptionsFilters = [
	{
		text: 'Adição de saldo',
		value: 'balance_adition',
	},
	{
		text: 'Compra cancelada',
		value: 'canceled_transaction',
	},
	{
		text: 'Compra nacional',
		value: 'national_purchase',
	},
	{
		text: 'Compra internacional',
		value: 'international_purchase',
	},
	{
		text: 'Estorno externo',
		value: 'external_refund',
	},
	{
		text: 'Estorno interno',
		value: 'internal_refund',
	},
	{
		text: 'IOF',
		value: 'fee',
	},
];
const filterParams: FilterParams[] = [
	{
		name: 'ID',
		filter: 'internal_transaction_id',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Status',
		filter: 'response_code',
		value: '',
		type: 'select',
		selectOptions: ResponseTypesOptionsFilters,
		selected: false,
	},
	// {
	// 	name: 'Dia do Mês',
	// 	filter: 'day',
	// 	value: '',
	// 	type: 'day',
	// 	selected: false,
	// },
	{
		name: 'Estabelecimento',
		filter: 'merchant_name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Lançamento',
		filter: 'transaction_type',
		value: '',
		type: 'select',
		selectOptions: transactionTypesOptionsFilters,
		selected: false,
	},
];

const useCardExtractListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useCardExtractListStore };
