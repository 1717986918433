import { useState } from 'react';
import { Filter } from '../../../components/Filter';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useQuery, useQueryClient } from 'react-query';
import { getCompaniesSelect } from '../../../services/queries/Companies';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import * as S from './styles';
import { CORPWAY_CARDS_KEY, getCards } from '../../../services/queries/Cards';
import * as TableStyles from '../../../components/Table/TableStyles';
import { Pagination } from '../../../components/Pagination';
import { EmptyContent } from '../../../components/EmptyContent';
import { checkCorpwayCardStatus } from '../../../utils/parseCropwayCardStatus';
import { useCorpwayCardsListStore } from '../../../stores/useCorpwayCardsListStore';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { BiTransfer } from 'react-icons/bi';
import { CardDetailsModal } from './CardDetailsModal';
import { transformCardToDetails } from './CardDetailsModal/corpwayDetailsInterface';

export function CorpwayCards() {
	const [currentPage, setCurrentPage] = useState(1);
	const queryClient = useQueryClient();

	const [filterParams, setFiltersParams, resetFilters] =
		useCorpwayCardsListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useSetHistoryNonMatchCallbackLocation('corpway-cards', resetFilters);

	const fetchListCompaniesQuery = useQuery(
		['companiesListSelectCards'],
		() => {
			return getCompaniesSelect();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as empresas.'
				);
			},
			enabled: true,
			refetchOnWindowFocus: false,
		}
	);

	const getCardsQuery = useQuery(
		['cardsListByCompany', CORPWAY_CARDS_KEY, filterParams, currentPage],
		() => getCards(currentPage, filterParams),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os cartões. ');
			},
			enabled: fetchListCompaniesQuery.isSuccess,
			refetchOnWindowFocus: false,
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('cardsListByCompany');
		queryClient.resetQueries('companiesListSelectCards');
	}

	if (getCardsQuery.isLoading || fetchListCompaniesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Cartões' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Cartões'
					totalRecords={getCardsQuery.data?.totalCards!}
				/>

				<Filter
					filterParams={filterParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={getCardsQuery.data?.totalCards!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{getCardsQuery.data?.cards.length! > 0 ? (
				<>
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>
									EMPRESA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									APELIDO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>PAN</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									STATUS
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									CENTRO DE CUSTO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
									OPÇÕES
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{getCardsQuery.data?.cards.map((card) => {
								const corpwayDetails = transformCardToDetails(card);

								return (
									<TableStyles.TableRow key={card.id}>
										<TableStyles.TableData>
											{card.company_data.name}
										</TableStyles.TableData>
										<TableStyles.TableData>{card.alias}</TableStyles.TableData>
										<TableStyles.TableData>{card.pan}</TableStyles.TableData>
										<TableStyles.TableData>
											{checkCorpwayCardStatus(card.status)}
										</TableStyles.TableData>
										<TableStyles.TableData>
											{card.cost_center_data?.title}
										</TableStyles.TableData>
										<TableStyles.TableData>
											<S.OptionsContainer>
												<CardDetailsModal
													corpwayCard={corpwayDetails}
												/>
												<S.OptionLinkButton
													data-rh='Transações'
													to={`/corpway-cards/${card.company_id}/extract-card/${card.id}/${card.pan}`}
												>
													<BiTransfer />
												</S.OptionLinkButton>

												<S.OptionsContainer>

												</S.OptionsContainer>
											</S.OptionsContainer>
										</TableStyles.TableData>
									</TableStyles.TableRow>

								)
							})}
						</TableStyles.TableBody>
					</TableStyles.Table>
				</>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
