import * as S from './styles';

export default function Footer() {
	return (
		<S.Container>
			<S.Copyright>
				&copy; {new Date().getFullYear()} Bounty - Bounty Tecnologia e
				Pagamentos LTDA
			</S.Copyright>
		</S.Container>
	);
}
