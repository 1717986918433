import { useEffect, useRef } from 'react';
import { useHistoryNonMatchCallbackStore } from '../stores/useHistoryNonMatchCallbackStore';

export function useSetHistoryNonMatchCallbackLocation(
	matchLocation: string,
	callback: () => void
) {
	const setNewMatchLocationAndCallback = useHistoryNonMatchCallbackStore(
		(state) => state.setNewMatchLocationAndCallback
	);

	const callbackRef = useRef(callback);

	useEffect(() => {
		setNewMatchLocationAndCallback(matchLocation, callbackRef.current);
	}, [matchLocation, setNewMatchLocationAndCallback]);
}
