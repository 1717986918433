import * as S from './styles';

interface PageTitleProps {
	title: string;
	color?: string;
	totalRecords?: number;
}

export default function PageTitle({
	title,
	totalRecords,
	color,
}: PageTitleProps) {
	return (
		<S.Container>
			<S.Title color={color}>{title}</S.Title>

			{!!totalRecords && (
				<S.TotalRecords data-testid='total-records'>
					Total: <b>{totalRecords}</b> registros
				</S.TotalRecords>
			)}
		</S.Container>
	);
}
