import styled from "styled-components";
import { OptionsButton } from "../../../../components/Button";

export const OpenModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const Container = styled.div`
	flex: 1;
	width: 80vw;
	height: 80vh;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const GridContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
    margin-top: 2rem;
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;

`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 1rem;
`;

export const InfoLabel = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--light-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const CopyIdBtn = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 4rem;
	width: fit-content;
`;

export const BttnContainer = styled.div`
	padding-bottom: 2rem;
	display: flex;
	column-gap: 1rem;
`;
